const localConfig = {
    domain: "exchange.nemolatam.com",
    api: "https://exchange.nemolatam.com/api/v1/",
    base: "",
    title: "NemoLATAM",
    captcha: "6Lf-74oqAAAAAD1AQTBuf1CfwgweXK2lx0rtikZb",
    countryCode: "PE",
    countryCurrency: "PEN",
    sentryEnv: "production",
    placesApiKey: "AIzaSyCZ5XUAvjpJooLHwr6bsA1Rm5zPEznKoBU"
}
export default localConfig;
