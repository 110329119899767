const routes = {
    main: "/",
    wallet: "/",
    converter: "/converter/:coin1/:coin2",
    deposit: "/deposit/:coin",
    withdraw: "/withdraw/:coin",
    cards: "/cards",
    profile: "/profile",
    kyc: "/kyc",
    login: "/login",
    register: "/register",
    confirmRegister: "/confirm-register/:email",
    resetPasswordRequest: "/reset-password/",
    resetPassword: "/reset-password/:uid/:token",
    maintenance: "/maintenance",
    notFound: "*",
  };
  
  export default routes;
  