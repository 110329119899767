import { useTranslation } from "react-i18next";
import {
  HiCheckCircle,
  HiCash,
  HiQuestionMarkCircle,
  HiClock,
  HiMinusCircle,
} from "react-icons/hi";
import coinlogos from "../../utils/coinlogos";
import { numberWithCommas } from "../../utils/functions";
import { useCoins } from "../../hooks/useCoins";
import './WalletHistoryModal.css'

interface WalletHistoryItem {
    id: number;
    created: number;
    updated: number;
    state: number;
    operation_type: "Deposit" | "Withdrawal"; 
    currency: string;
    amount: number;
    fee_amount: number;
}

const WalletHistoryModal = ({ item }: { item: WalletHistoryItem }) => {
    const { t } = useTranslation("common");
    const { data: coinsData } = useCoins();

    const originalAmount = item.amount + item.fee_amount;

    const statusKeys: Record<number, string> = {
        0: "pending",
        1: "to_be_sent",
        2: "done",
        3: "canceled",
        4: "failed",
        5: "unknown",
        6: "wait_confirmation",
        7: "reverted",
        8: "verifying",
    };

    const iconsStatus: Record<number, JSX.Element> = {
        0: <HiClock className="wallet-history-status-icon" />,
        1: <HiClock className="wallet-history-status-icon" />,
        2: <HiCheckCircle className="wallet-history-status-icon" />,
        3: <HiMinusCircle className="wallet-history-status-icon" />,
        4: <HiMinusCircle className="wallet-history-status-icon" />,
        5: <HiQuestionMarkCircle className="wallet-history-status-icon" />,
        6: <HiClock className="wallet-history-status-icon" />,
        7: <HiCash className="wallet-history-status-icon" />,
        8: <HiClock className="wallet-history-status-icon" />,
    };

    const formattedOriginal = numberWithCommas(`${originalAmount}`, coinsData?.coins[item.currency]?.decimals);
    const formattedFee = numberWithCommas(`${item.fee_amount}`, coinsData?.coins[item.currency]?.decimals);
    const formattedTotal = numberWithCommas(`${item.amount}`, coinsData?.coins[item.currency]?.decimals);

    const formattedTime = new Date(item.created).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit", hour12: false});
    const formattedDate = new Date(item.created).toLocaleDateString("en-GB", {day: "2-digit", month: "2-digit", year: "2-digit"});

    return (
        <div className="wallet-history-container">
            {/* Header*/}
            <div className="wallet-history-top">
                <div className="wallet-history-coin">
                    <img
                        className="wallet-history-coin-logo"
                        src={coinlogos[item.currency as keyof typeof coinlogos]}
                        alt={item.currency}
                    />
                    <span className="wallet-history-coin-symbol">
                        {item.currency}
                    </span>
                </div>
                {item.operation_type && (
                    <div className="wallet-history-operation-type">
                        {t(`wallet_history_item.${item.operation_type}`)}
                    </div>
                )}
            </div>

            {/* Amounts */}
            <div className="wallet-history-amounts">
                <div className="wallet-history-amount-block">
                <span className="wallet-history-amount-label">
                        {t("wallet_history_item.original_label")}
                    </span>
                    <span className="wallet-history-amount-value">
                        {formattedOriginal} {item.currency}
                    </span>
                </div>
                <div className="wallet-history-amount-block">
                    <span className="wallet-history-amount-label">
                        {t("wallet_history_item.fee_label")}
                    </span>
                    <span className="wallet-history-amount-value">
                        {formattedFee === "null" ? 0 : formattedFee} {item.currency}
                    </span>
                </div>
                <div className="wallet-history-amount-block">
                    <span className="wallet-history-amount-label">
                        {t("wallet_history_item.total_label")}
                    </span>
                    <span
                        className={`wallet-history-amount-value ${
                        item.amount > 0 ? "positive" : "negative"
                        }`}
                    >
                        {formattedTotal} {item.currency}
                    </span>
                </div>
            </div>

            {/* Footer */}
            <div className="wallet-history-bottom">
                <div className="wallet-history-date-time">
                    {formattedDate} {formattedTime}
                </div>
                <div
                    className={`wallet-history-status status-${
                        item.state
                    }`}
                >
                    {iconsStatus[item.state]}
                    <span className="wallet-history-status-text">
                        {t(`status_mapping.${statusKeys[item.state]}`)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WalletHistoryModal;
