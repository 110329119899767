import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner/Spinner";
import useModalStore from "../../stores/ModalStore";
import "./ConfirmModal.css";

interface ConfirmModalProps {
    data: {
        title?: string;
        info?: string;
        description?: string;
        link?: string;
        action?: string;
    };
    onConfirm: () => Promise<void>;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ data, onConfirm }) => {
    const { t } = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);

    const { closeModal } = useModalStore((state) => {
        return {
            closeModal: state.closeModal,
        }
    });

    const handleConfirmClick = async () => {
        setIsLoading(true);
        try {
            await onConfirm();
        } finally {
            setIsLoading(false);
            closeModal();
        }
    };

    return (
        <div className="confirm-modal-cont">
            {data?.title && <h3 className="confirm-modal-title">{data.title}</h3>}
            {data?.info && <p>{data.info}</p>}
            {data?.description && <p>{data.description}</p>}
            {data?.link && (
                <a
                href={data.link}
                target="_blank"
                rel="noreferrer"
                className="confirm-modal-link"
                >
                {t("click_here")}
                </a>
            )}

            {data?.action && (
                <div className="confirm-modal-button-section">
                    {isLoading ? 
                    <Spinner /> 
                    : 
                    <button
                        type="button"
                        className="confirm-modal-button"
                        onClick={handleConfirmClick}
                        disabled={isLoading}
                    >
                        {data.action}
                    </button>}
                </div>
            )}
        </div>
    );
};

export default ConfirmModal;
