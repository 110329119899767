import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"


const fetchAddresses = () => {
    return axiosInstance.get("/addresses/")
}

const postAddress = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/addresses/", formData);
}

const deleteAddress =  async (id: string) => {
    return axiosInstance.delete(`/addresses/${id}`);
}


export const useAddresses = () => {
    return useQuery({
        queryKey: ['addresses'],
        queryFn: fetchAddresses,
        select: (data) => {
            return data.data;
        }
    });
}

export const usePostAddress = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postAddress(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
        }
    });
}

export const useDeleteAddress = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (id: string) => deleteAddress(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
        }
    });
}


