import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useUpdateCard, useCardDetails } from "../../hooks/useCards";
import { CardInfo } from "../../utils/cards";
import { Link } from "react-router-dom"
import { CARD_ACTIONS, CardActionType } from "../../utils/cards";
import "./CardActionModal.css"
import Spinner from "../../components/Spinner/Spinner";
import useModalStore from "../../stores/ModalStore";
import toast from "react-hot-toast";
import localConfig from "../../local_config";

interface CardActionProps {
    card: CardInfo;
    action: CardActionType;
}

const CardActionModal = ({ card, action } : CardActionProps) => {

    const { t } = useTranslation('common');
    const { mutateAsync: updateCard } = useUpdateCard();
    const [ isLoading, setIsLoading ] = useState(false);

    const { data: cardDetails, isFetching: isCardDetailsLoading } = useCardDetails(
        card.tracking_number,
        action === "details"
    );

    const { closeModal } = useModalStore((state) => {
        return {
            closeModal: state.closeModal,
        }
    });

    const cardDetailsData = localConfig.countryCode == "PE" ? card?.data : cardDetails;

    const handleAction = async () => {
        const new_status = CARD_ACTIONS[action];
        if (new_status) {
            try {
                setIsLoading(true);
                await updateCard({ id: card.tracking_number, formData: {"status": new_status} });
                setIsLoading(false);
                toast.success(t(`card_action_modal.${action}.success`));
                closeModal();
            }
            catch(error: any) {
                setIsLoading(false);
                if (error.response.status == 400)
                    toast.error(t(`card_action_modal.${action}.${card.vendor}_error`));
                else
                    toast.error(t('try_later_or_contact_support'));
            }
        }
    }

    return (
        <div className="card-action-status-cont">
           <h3 className="card-action-status-title">{t(`card_action_modal.${action}.title`)}</h3>

           <div className="card-action-status-info">
                {t(`card_action_modal.${action}.text`) && <div className="card-action-status-info-text">{t(`card_action_modal.${action}.text`)}</div>}

                {action === "fund" && 
                    <Link to={`/withdraw/${card?.currency}`} style={{textDecoration: "none"}} className="card-action-withdrawal-button">{t('withdraw')}</Link>
                }

                {/* Card data */}
                {action === "details" && <div className="card-data">
                    {isCardDetailsLoading ? <Spinner /> 
                    : 
                        <>
                        {Object.entries(cardDetailsData).map(([key, value]) => (
                            value != null &&
                            <div className="card-data-item">
                                <div className="card-data-item-title">{t(key)}</div>
                                <div className="card-data-item-value">{typeof value === "string" && t(value)}</div>
                            </div>
                        ))}
                        {localConfig.countryCode != "PE" && <div className="card-data-warning">{t("nemo_card_details_warning")}</div>}
                        </>
                    }                    
                </div>}

           </div>

           {CARD_ACTIONS[action] && (
            isLoading ? <Spinner /> : 
            <div className="card-action-status-action-buttons">
                <div className="card-action-withdrawal-button" onClick={handleAction}>
                    {t("confirm")}
                </div>
            </div>)}
        </div>
    )

}

export default CardActionModal