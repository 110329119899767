import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from "@sentry/react";
import local_config from "./local_config.tsx"
import { HelmetProvider } from "react-helmet-async";
import App from './App.tsx'
import './i18n.ts';
import './index.css'


Sentry.init({
  dsn: "https://e51ffa23f2e0fb393239d3bf7c8c7c8c@o4508367323856896.ingest.us.sentry.io/4508399987392512", 
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  environment: local_config?.sentryEnv ? local_config.sentryEnv : "noenv",
  tracePropagationTargets: [
    /^https:\/\/yourserver\.io\/api/,
    /^https:\/\/exchange\.nemolatam\.co/,
    /^https:\/\/exchange\.nemolatam\.com/,
    /^https:\/\/exchange\.testperu\.com/
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
})

function loadGoogleMapsScript(): void {
  if (document.getElementById("google-maps-script")) return;

  const script = document.createElement("script");
  script.id = "google-maps-script";
  script.src = `https://maps.googleapis.com/maps/api/js?key=${local_config.placesApiKey}&libraries=places`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

const queryClient = new QueryClient()

loadGoogleMapsScript();

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
)
