import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"


const fetchCards = () => {
    return axiosInstance.get("/cards/")
}

const fetchCardRequest = () => {
    return axiosInstance.get("/cardrequest/")
}

const inswitchCardDetails = async (card_reference: string) => {
    return axiosInstance.get('/card_details/', { params: { card_reference: card_reference } })
}

const updateCard = async (id: string, formData: { [key: string]: any }) => {
    return axiosInstance.put(`/cards/${id}/`, formData)
}

const createCard = async () => {
    return axiosInstance.post('/cards/')
}

const createCardRequest = async (formData: { [key: string]: any }) => {
    return axiosInstance.post('/cardrequest/', formData)
}

const deleteCardRequest = async (id: string) => {
    return axiosInstance.delete(`/cardrequest/${id}`)
}

export const useCards = () => {
    return useQuery({
        queryKey: ['cards'],
        queryFn: fetchCards,
        select: (data) => {
            return data.data;
        }
    });
}

export const useCardRequest = () => {
    return useQuery({
        queryKey: ['cardrequest'],
        queryFn: fetchCardRequest,
        select: (data) => {
            return data.data;
        }
    });
}

export const useCardDetails = (card_reference: string, enabled: boolean) => {
    return useQuery({
      queryKey: ["card_details", card_reference],
      queryFn: () => inswitchCardDetails(card_reference),
      enabled,
      select: (data) => data.data
    });
};

export const useUpdateCard = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ id, formData }: { id: string, formData: { [key: string]: any } }) => updateCard(id, formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cards'] });
        }
    });
}

export const usePostCard = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: () => createCard(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cards'] });
        }
    });
}

export const usePostCardRequest = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ formData }: { formData: { [key: string]: any } }) => createCardRequest(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cardrequest'] });
        }
    });
}

export const useDeleteCardRequest = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (id: string) => deleteCardRequest(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cardrequest'] });
        }
    });
}