import { useEffect, useState } from "react"
import { useCoins } from "../../hooks/useCoins"
import { useBalance } from "../../hooks/useBalance"
import { useWallets, usePostWallet } from "../../hooks/useWallets"
import { useLimits } from "../../hooks/useLimits"
import { usePostInswitch } from "../../hooks/useInswitch"
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FaCopy } from "react-icons/fa6"
import { QRCode } from 'react-qrcode-logo'
import useModalStore from "../../stores/ModalStore"
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced"
import Inswitch from "../../components/Inswitch/Inswitch"
import InfoModal from "../../modals/InfoModal/InfoModal"
import FeeLimits from "../../components/FeeLimits/FeeLimits"
import qrImg from "../../assets/logos/logo.svg"
import InOutHistory from "../../components/InOutHistory/InOutHistory"
import cloneDeep from 'lodash/cloneDeep';
import toast from "react-hot-toast"

import "./Deposit.css"
import Spinner from "../../components/Spinner/Spinner"
import localConfig from "../../local_config"

const Deposit = () => {
    const { t } = useTranslation('common')
    
    const { coin } = useParams<{ coin: string }>()
    
    const navigate = useNavigate()

    const { data: coinsData } = useCoins()
    const { data: balanceData } = useBalance()
    const { data: walletsData } = useWallets()
    const { data: limits }  = useLimits()

    const { mutateAsync: depositRequest } = usePostInswitch()
    const { mutateAsync: createWallet } = usePostWallet()

    const [selectedCoin, setSelectedCoin] = useState(coin || "USDT")
    const [selectedBlockchain, setSelectedBlockchain] = useState("")
    const [depositAmount, setDepositAmount] = useState(0)
    const [wallet, setWallet] = useState<any>()
    const [formData, setFormData] = useState<{ [key: string]: any }>({})
    const [errorMsg, setErrorMsg] = useState("")
    const [isPending, setIsPending] = useState(false)
    const [inswitchKey, setInswitchKey] = useState(0); 

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    })

    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string
                price?: number
                logo?: string
                decimals?: number
            }
        } = {}
        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            if(localConfig.countryCode == 'PE' && coinsData.coins[key].is_fiat) return;
            items[key] = {
                name: coinsData.coins[key].name,
                logo: coinsData.coins[key].logo,
                price: balanceData?.balance[key] ? balanceData.balance[key].actual + balanceData.balance[key].orders : "0",
                decimals: coinsData.coins[key].decimals,
            }
        })
        return items
    }

    const [defaultFields, setDefaultFields] = useState([
        {   "description": "Cantidad",
            "fieldType": "input",
            "isUserField": true,
            "mandatory": true,
            "name": "amount",
            "regex": "^\\d+(\\.\\d+)?$",
            "errorMsg": "Debe ser un número",
            "validOptions": [],
            "value": 0,
            "handleInputChange": (value: number) => {
                setDefaultFields((prev) => {
                    let clone = cloneDeep(prev)
                    clone[0].value = value
                    return clone
                })
                setDepositAmount(value)
            }
        },
        {   "description": "Descripción",
            "fieldType": "input",
            "isUserField": true,
            "mandatory": false,
            "name": "descriptionText",
            "regex": ".*",
            "validOptions": [],
            "value": "vendor"
        }
    ])
    
    const handleCoinChange = (value: string) => {
        setSelectedCoin(value)
        navigate("/deposit/"+value)
    }

    const handleBlockchainChange = (value: string) => {
        setSelectedBlockchain(value)
    }

    const handleFormDataChange = (data: object) => {
        setErrorMsg("")
        setFormData(data)
    }

    const validateFormData = (data: { [key: string]: any }) => {
        if(!data) return false;
        return Object.values(data).every(value => value !== "");
    };

    const blockchainList = () => {
        return coinsData?.coins[selectedCoin]?.blockchain_list.length > 0
            ? coinsData?.coins[selectedCoin]?.blockchain_list.reduce((acc : { [key: string]: any }, item: string) => {
                acc[item] = { name: item, id: item }
                return acc
            }, {})
            : { [selectedCoin]: { name: selectedCoin } }
    }
    
    useEffect(() => {
        if(!coinsData || !walletsData) return;
        if(selectedBlockchain == "") return 
        if(!(selectedBlockchain in blockchainList())) return

        const walletAddress = walletsData.filter((wallet : { [key: string]: any }) => {
            return wallet.currency === selectedCoin && wallet.blockchain_currency === selectedBlockchain
        })

        setWallet(walletAddress && walletAddress.length > 0 ? walletAddress[0] : {} )

    }, [walletsData, selectedCoin, selectedBlockchain])

    const copyToClipboard = () => {
        toast.dismiss()
        navigator.clipboard.writeText(wallet.address).then(() => {
            toast.success(t("sucess_copy_address"))
        }).catch((err) => {
            console.log(err)
            toast.error(t("error_copy_address"))
        })
      }

    const handleSubmit = async () => {
        try {

            setIsPending(true)
            if(!validateFormData(formData.data)){
                setIsPending(false)
                toast.dismiss()
                toast.error(t("missing_form_fields"));
                return
            }

            const result = await depositRequest(
                {...Object.fromEntries(
                    defaultFields.map(defaultField => [defaultField.name, defaultField.value])
                ),
                ...{currency: selectedCoin},
                ...formData}
            )

            setIsPending(false)

            if (result.status === 201) {
                setInswitchKey(prevKey => prevKey + 1);
                setDepositAmount(0);
                const instructions = result.data.instructions
                window.open(instructions, '_blank')
                setModalContent(
                    <InfoModal data={{
                        title: t('modal_deposit.title'),
                        info: t('modal_deposit.info'),
                        description: t('modal_deposit.description'),
                        link: instructions,
                        action: t('modal_deposit.button'),
                        action_url: '/',
                        }} 
                    />
                )
            }

        } catch (error) {
            toast.dismiss()
            setIsPending(false)
            console.error("Error al hacer la solicitud a la API:", error)
            toast.error(t("unexpected_error"))
        }
    }

    const handleWalletCreation = async () => {

        try {
            if(!selectedCoin || !selectedBlockchain) return
            setIsPending(true)
            const result = await createWallet(
                {
                    currency: selectedCoin,
                    blockchain_currency: selectedBlockchain
                }
            )

            if (result.status === 200) {
                setWallet(result.data[0])
            }
            setIsPending(false)
        }
        catch(e){
            setIsPending(false)
            toast.dismiss()
            toast.error(t(""))
        }

    }

    return (
        <>
            <div className="outlet-page-main-cont">
                <div className="deposit-main-cont">
                    <div className="deposit-deposit-cont">
                        <h1 className="deposit-title">{t("deposit")}</h1>
                        <div className="deposit-body">
                                <SelectAdvanced
                                    label={t("select_coin")}
                                    value={selectedCoin}
                                    items={coinsItems()}
                                    image={true}
                                    onChange={handleCoinChange}
                                />
                                {
                                    coinsData && coinsData.coins && selectedCoin in coinsData.coins
                                        ? (
                                            coinsData.coins[selectedCoin].is_fiat ? (
                                                <div className="deposit-fiat-content">
                                                    <Inswitch
                                                        key={inswitchKey}
                                                        action="in"
                                                        currency={selectedCoin}
                                                        onFormDataChange={handleFormDataChange}
                                                        defaultFields={defaultFields}
                                                    />
                                                    <FeeLimits
                                                        ticker={selectedCoin}
                                                        amount={depositAmount} 
                                                        minLimit={limits?.[selectedCoin].limits.deposit.min || 0}
                                                        maxLimit={limits?.[selectedCoin].limits.deposit.max || 0}
                                                        fee={limits?.[selectedCoin].fee.deposit[formData?.blockchain?.toUpperCase() + '_FEE']?.fee || 0}
                                                        minFee={limits?.[selectedCoin].fee.deposit[formData?.blockchain?.toUpperCase() + '_FEE']?.min || 0}
                                                        flat={!coinsData?.coins[selectedCoin]?.is_fiat}
                                                        onLimitCheck={function() {}}
                                                        isPending={isPending}
                                                        buttonAction={handleSubmit}
                                                    />
                                                    {
                                                        errorMsg && (
                                                            <div className="error-msg">
                                                                {errorMsg}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className="deposit-crypto-content">
                                                    <SelectAdvanced
                                                        label={t("select_blockchain")}
                                                        value={selectedBlockchain}
                                                        items={blockchainList()}
                                                        image={false}
                                                        onChange={handleBlockchainChange}
                                                    />
                                                    {
                                                        selectedBlockchain != "" && (

                                                            !wallet ? <> </> :
                                                            
                                                            wallet.address ? (
                                                            
                                                            <div className="deposit-address-cont">
                                                                <div className="deposit-wallet-container">
                                                                    <div className="deposit-wallet">
                                                                        <div>
                                                                        {t("address")}
                                                                        </div>
                                                                        <div className="deposit-wallet-address">
                                                                            {wallet.address}
                                                                        </div>
                                                                </div>
                                                                <div className="copy-button" onClick={copyToClipboard}>
                                                                    <FaCopy fontSize={20}/>
                                                                </div>
                                                                </div>
                                                                <FeeLimits
                                                                    ticker={selectedCoin}
                                                                    amount={0} 
                                                                    minLimit={limits?.[selectedCoin].limits.deposit.min || 0}
                                                                    maxLimit={limits?.[selectedCoin].limits.deposit.max || 0}
                                                                    fee={limits?.[selectedCoin].fee.deposit.address[selectedCoin]}
                                                                    flat={!coinsData?.coins[selectedCoin]?.is_fiat}
                                                                    onLimitCheck={function() {}}
                                                                />
                                                                <div className="qr-div">
                                                                    <QRCode 
                                                                        value={wallet.address}
                                                                        size={200}
                                                                        bgColor="#0F131A"
                                                                        fgColor="#8FFDFC"
                                                                        logoImage={qrImg}
                                                                        logoWidth={70}
                                                                        logoPadding={5}
                                                                        logoPaddingStyle="circle"
                                                                        removeQrCodeBehindLogo={true}
                                                                        ecLevel="Q"
                                                                    />
                                                                </div>
                                                            </div>
                                                            ) : (
                                                                <div className="create-wallet-div">

                                                                    <div>
                                                                        <div className="create-wallet-title">
                                                                        {t("create_wallet_title")} {selectedCoin}
                                                                        </div>
                                                                        <div className="create-wallet-sub-title">
                                                                        {t("create_wallet_sub_title")} {selectedBlockchain}
                                                                        </div>
                                                                    </div>
                                                                    <button  disabled={isPending} className="create-wallet-button" onClick={handleWalletCreation}>
                                                                        {t("create_wallet")}
                                                                    </button>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                        </div>

                                            )
                                        ) : <Spinner />
                                }
                        </div>
                    </div>
                    <div className="deposit-history">
                        <InOutHistory filterDefault='positive' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deposit
