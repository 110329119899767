import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ligocard from "../../assets/logos/ligocard.svg";
import nemocard from "../../assets/logos/nemocard.svg";
import useModalStore from "../../stores/ModalStore";
import { FaCheckCircle, FaLock, FaEye } from "react-icons/fa";
import { RiInformation2Line } from "react-icons/ri";
import { FiArrowDownRight } from "react-icons/fi";
import { CardInfo, CardStatuses, CardActionType } from "../../utils/cards";
import { useCoins } from "../../hooks/useCoins";
import { useBalance } from "../../hooks/useBalance";
import CardActionModal from "../../modals/CardActionModal/CardActionModal";
import InfoModal from "../../modals/InfoModal/InfoModal";
import SelectAdvanced from "../SelectAdvanced/SelectAdvanced";
import localConfig from "../../local_config";
import { numberWithCommas } from "../../utils/functions";
import "./NemoCard.css"

interface NemoCardProps {
    card: CardInfo;
}

const NemoCard = ( { card } : NemoCardProps) => {

    const { t } = useTranslation('common');

    const { data: coinsData } = useCoins();
    const { data: balanceData } = useBalance();

    // NOTE "CO" is hardcoded
    const [selectedCoin, setSelectedCoin] = useState(card?.fund_currency || (localConfig.countryCode === "CO" ? "USDT" : "USD"));
    const [selectedCoinBalance, setSelectedCoinBalance] = useState({balance: 0, decimals: 2});

    const cardOptions: {
        label: string;
        action: CardActionType;
        icon: JSX.Element;
        isDisabled: boolean;
    }[] = [
        {
            label: "activate_card",
            action: "activate",
            icon: <FaCheckCircle />,
            isDisabled: card.status === CardStatuses.ACTIVE || card.status === CardStatuses.BLOCKED,
        },
        {
            label: "block_card",
            action: "blocked",
            icon: <FaLock />,
            isDisabled: card.status === CardStatuses.BLOCKED || card.status === CardStatuses.ASSIGNED || card.status === CardStatuses.INACTIVE,
        },
        {
            label: "show_details",
            action: "details",
            icon: <FaEye />,
            isDisabled: card.status != CardStatuses.ACTIVE,
        },
        // NOTE In PE we have Inswitch, which currently doesnt need to add funds
        ...(localConfig.countryCode !== "CO"
            ? [
                {
                    label: "add_funds",
                    action: "fund",
                    icon: <FiArrowDownRight />,
                    isDisabled: card.status === CardStatuses.BLOCKED,
                } as const,
              ]
            : []),
    ];
    
    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string;
                logo?: string;
                decimals?: number;
            }
        } = {};
    
        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            //
            // NOTE THIS IS HARDCODED TO BE REMOVED WHEN WE ENABLE CARD AUTO CONVERSION
            //
            if (key == 'USDT') { 
                items[key] = {
                    name: coinsData.coins[key].name,
                    logo: coinsData.coins[key].logo,
                    decimals: coinsData.coins[key].decimals,
                };
            }
        });

        return items;
    };

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    });

    const handleCoinChange = (value: string) => {
        setSelectedCoin(value);
        // TODO this should send a update card to update the cards fund coin
    };

    useEffect(() => {
        if (selectedCoin) {
            const balance = balanceData
                    ? balanceData.balance[selectedCoin]
                        ? balanceData.balance[selectedCoin].actual + balanceData.balance[selectedCoin].orders
                        : "0"
                    : "0"
            const decimals = coinsData?.coins[selectedCoin]?.decimals || 2;
            setSelectedCoinBalance({balance: balance, decimals: decimals});
        }
    }, [selectedCoin, balanceData]);

    return (
        <div className="nemo-card-main-cont">
            <div className="nemo-card-title">{t(card.vendor)} <span className={`card-${card.status}`}>{`(${t(card.status)})`}</span></div>
            <div className="nemo-card-cont">
                <div className="nemo-card-left">
                    <img className={`card-img card-img-${card.status}`} src={card.vendor == 'ligo' ? ligocard : nemocard} />
                </div>
                <div className="nemo-card-right">
                    {
                    // Perú has LIGO which needs balance and options
                    localConfig.countryCode === "PE" ? 
                    <>
                        <div>{t("card_balance")}: ${card.balance} USD</div>
                        <div>{t("card_options_title")}</div>
                    </> : 
                    // Colombia has INSWITCH which needs a coin selector and the coin balance
                    localConfig.countryCode === "CO" && <>
                        <div className="nemo-card-coin">
                            {t("card_coin")} 
                            <div 
                                className="nemo-info-icon"
                                onClick={() => setModalContent(<InfoModal data={{title: t("card_asset_info_modal.title"), description: t("card_asset_info_modal.text")}}/>)}

                            >
                                <RiInformation2Line />
                            </div>
                        </div>
                        <SelectAdvanced
                            value={coinsData ? selectedCoin : ""}
                            items={coinsItems()}
                            image={true}
                            onChange={handleCoinChange}
                        />
                        <div className="nemo-card-balance">
                            <div>{t("card_balance")}:</div>
                            <div className="card-balance">{numberWithCommas(selectedCoinBalance?.balance, selectedCoinBalance?.decimals)} {selectedCoin}</div>
                        </div>
                    </>
                    }
                    {/* Card options */}
                    <div className="nemo-card-options">
                        {cardOptions.map((option) => (
                            <div
                                className={`card-option ${option.isDisabled ? "disabled" : ""}`}
                                key={option.action}
                                onClick={() =>
                                    !option.isDisabled &&
                                    setModalContent(<CardActionModal card={card} action={option.action} />)
                                }
                            >
                                <div className="card-option-circle">
                                    <div className="card-option-icon">{option.icon}</div>
                                </div>
                                <div className="card-option-label">{t(option.label)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NemoCard