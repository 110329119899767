import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { useInswitch } from "../../hooks/useInswitch";
import SelectAdvanced from "../SelectAdvanced/SelectAdvanced";
import InputAdvanced from "../Input/InputAdvanced";
import defaultImg from "../../assets/common/default.svg" 
import "./Inswitch.css"
import Spinner from "../Spinner/Spinner";

interface Vendor {
    paymentMethodTypeClass: string;
    paymentMethodTypeDescription: string;
    paymentMethodType: string;
    imageURL: string;
    fields: { [key: string]: any }[]; 
}

interface UseInswitchResponse {
    data: Vendor[] | undefined;
}

interface InswitchProps {
    action: string;
    currency: string;
    defaultFields: { [key: string]: any }[];
    onFormDataChange: (data: object) => void;
}

const Inswitch = ({ action, currency, defaultFields, onFormDataChange} : InswitchProps) => {
    const { t } = useTranslation('common')
    
    const { data: inswitchData}: UseInswitchResponse  = useInswitch(action, currency);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [formData, setFormData] = useState<{ [key: string]: any }>({})
    const [optionFields, setOptionFields] = useState<{ [key: string]: any }>({}) 

    useEffect(() => {
        setOptionFields(inswitchData && selectedOption ? inswitchData
            .filter( vendor => vendor.paymentMethodType === selectedOption
            )[0] : {})
    },[selectedOption])

    const methodTypes = inswitchData ? Object.fromEntries(
            inswitchData.map( vendor => [
                    vendor.paymentMethodTypeClass, {
                        id: vendor.paymentMethodTypeClass, 
                        name: vendor.paymentMethodTypeClass,
                    }
                ]
            )
        ) : {}

    const methodsOptions = inswitchData ? Object.fromEntries( inswitchData
                    .filter(method => method.paymentMethodTypeClass === selectedMethod )
                    .map(
                        method => [
                            method.paymentMethodType,
                            {
                                id: method.paymentMethodType,
                                name: method.paymentMethodTypeDescription,
                                logo: method.imageURL  || defaultImg,
                            }
                        ]
                    )
                )
        : {}

    useEffect( ()=> {
        const optionFieldsData : { [key: string]: any } = inswitchData && selectedOption ? inswitchData
            .filter( vendor => vendor.paymentMethodType === selectedOption
            )[0] : {}
        const newFormData = {
                direction: optionFieldsData?.direction,
                method: optionFieldsData?.paymentMethodType,
                method_type: optionFieldsData?.paymentMethodTypeClass,
                data: optionFieldsData?.fields?.reduce((acc : { [key: string]: any }, curr: { [key: string]: any }) => {
                    if (curr.mandatory) {
                      acc[curr.name] = "";
                    }
                    return acc;
                  }, {}),
                blockchain: optionFieldsData?.paymentMethodTypeClass,
                country: optionFieldsData?.country
        }
        setFormData(newFormData)
    }, [inswitchData, selectedOption])

    const handleMethodChange = (value: string) => {
        setSelectedMethod(value);
        setSelectedOption("");
        onFormDataChange({blockchain: value})
    };
    
    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleInputChange = (field: { [key: string]: any }, value: string) => {
        if (field.handleInputChange) {
            field.handleInputChange(value);
        }
        const regex = new RegExp(field.regex);
        let message = "";
        let new_value = "";
        if (value != "" && !regex.test(value)) {
            message = "Valor inválido";
            new_value = ""
        }
        else {
            message = ""
            new_value = value
        }
        setFormData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [field.name]: new_value,
            },
        }));
        return message
    };

    useEffect(() => {
        onFormDataChange(formData);
      }, [formData]);
    
    const formatSelectInputs = (options: string[]) => {
        return Object.fromEntries(
            options.map(option => [
                option, 
                { id: option, name: option }
            ])
        );
    };

    return (
        <>
        {
            inswitchData ? (
                <div className="inswitch-fields-cont">
                    <SelectAdvanced
                        label={t("select_method")}
                        value={selectedMethod}
                        items={methodTypes}
                        image={false}
                        onChange={handleMethodChange}
                    />
                    {
                        selectedMethod && (
                            <SelectAdvanced
                                label={t("select_option")}
                                value={selectedMethod}
                                items={methodsOptions}
                                image={true}
                                onChange={handleOptionChange}
                            />
                        )
                    }
                    <form className="inswitch-fields">
                        {optionFields?.fields?.map((field: any, index: number) => (
                            field.mandatory && (
                                <div key={index} className="field-container">
                                    {field.fieldType === 'input' ? (
                                        <InputAdvanced  field={field} onInputChange={handleInputChange}/>
                                    ) : field.fieldType === 'select' ? (
                                        <SelectAdvanced
                                            value={formData?.data?.[field.name]}
                                            label = {t(field.name) || field.name}
                                            items={formatSelectInputs(field.validOptions)}
                                            image={false}
                                            onChange={(e) => {
                                                handleInputChange(field, e);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            )
                        ))}
                        {defaultFields.map((field: any, index: number) => (
                            field.mandatory && (
                                <div key={index} className="field-container">
                                    {field.fieldType === 'input' ? (
                                        <InputAdvanced  field={field} onInputChange={handleInputChange}/>
                                    ) : field.fieldType === 'select' ? (
                                        <SelectAdvanced
                                            value={formData?.data?.[field.name]}
                                            label = {t(field.name) || field.name}
                                            items={formatSelectInputs(field.validOptions)}
                                            image={false}
                                            onChange={(e) => {
                                                handleInputChange(field, e);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            )
                        ))}
                    </form>
                    {(selectedMethod === 'bank' || selectedMethod === 'card' || selectedMethod === 'bank_transfer')  && <div className="inswitch-disclaimer">*{t("inswitch_disclaimer")}</div>}
                </div>
                ) : (
                    <Spinner />
                )
            }
        </>
    );
}

export default Inswitch;
