import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePostAddress } from "../../hooks/useAddresses";
import { FaSearch } from "react-icons/fa";
import useModalStore from "../../stores/ModalStore";
import toast from "react-hot-toast";
import "./AddAddressModal.css";
import Spinner from "../../components/Spinner/Spinner";

declare global {
    interface Window {
        google: any;
    }
}

const AddAddressModal = () => {

    const { t } = useTranslation("common");
    const { mutateAsync: postUserAddress } = usePostAddress();

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }));

    const [formData, setFormData] = useState({
        street: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!window.google || !searchInputRef.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current, {
            types: ["address"],
        });

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place || !place.address_components) return;

            const getAddressComponent = (type: string) => {
                const c = place.address_components.find((comp: any) => comp.types.includes(type));
                return c ? c.long_name : "";
            };

            const streetNumber = getAddressComponent("street_number");
            const route = getAddressComponent("route");
            const city = getAddressComponent("locality");
            const state = getAddressComponent("administrative_area_level_1");
            const zip_code = getAddressComponent("postal_code");
            const country = getAddressComponent("country");

            setFormData({
                street: `${streetNumber} ${route}`.trim(),
                city,
                state,
                zip_code,
                country,
            });
        });
    }, []);

    const handleInputChange = (field: keyof typeof formData, value: string) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const isFormValid = Object.values(formData).every((val) => val.trim() !== "");

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const result = await postUserAddress(formData);
            
            setIsLoading(false);
            if (result.status === 201) {
                toast.dismiss();
                toast.success(t("user_address.address_added"));
                closeModal();
            }
        } catch (e: any) {
            setIsLoading(false);
            if (e?.response?.data?.error) {
                toast.dismiss();
                toast.error(t("user_address.too_many_addresses"));
            } else {
                toast.dismiss();
                toast.error(t("user_address.unexpected_error"));
            }   
        }
    };

    return (
        <div className="add-address-modal">
            <div className="create-address-title">{t("user_address.title")}</div>
            <div className="address-field">
                <div>{t("user_address.search_address_label")}</div>
                <div className="search-address-field">
                    <input
                        ref={searchInputRef}
                        type="text"
                        className="search-address-field-input"
                        placeholder={t("user_address.search_address_placeholder")}
                    />
                    <FaSearch className="search-address-icon"/>
                </div>
            </div>
            <div className="address-field-container">
                <div className="address-field">
                    <div>{t("user_address.street_label")}</div>
                    <input
                        className="address-field-value"
                        value={formData.street}
                        onChange={(e) => handleInputChange("street", e.target.value)}
                        placeholder={t("user_address.street_placeholder")}
                    />
                </div>
                <div className="address-field">
                    <div>{t("user_address.city_label")}</div>
                    <input
                        className="address-field-value"
                        value={formData.city}
                        onChange={(e) => handleInputChange("city", e.target.value)}
                        placeholder={t("user_address.city_placeholder")}
                    />
                </div>
                <div className="address-field">
                    <div>{t("user_address.state_label")}</div>
                    <input
                        className="address-field-value"
                        value={formData.state}
                        onChange={(e) => handleInputChange("state", e.target.value)}
                        placeholder={t("user_address.state_placeholder")}
                    />
                </div>
                <div className="address-field">
                    <div>{t("user_address.zip_code_label")}</div>
                    <input
                        className="address-field-value"
                        value={formData.zip_code}
                        onChange={(e) => handleInputChange("zip_code", e.target.value)}
                        placeholder={t("user_address.zip_code_placeholder")}
                    />
                </div>
                <div className="address-field">
                    <div>{t("user_address.country_label")}</div>
                    <input
                        className="address-field-value"
                        value={formData.country}
                        onChange={(e) => handleInputChange("country", e.target.value)}
                        placeholder={t("user_address.country_placeholder")}
                    />
                </div>
            </div>
            <div className="address-action-button-div">
                {isLoading ? (
                    <Spinner />
                ) : (
                    <button className="address-action-button" disabled={!isFormValid} onClick={handleSubmit}>
                        {t("user_address.submit")}
                    </button>
                )}
            </div>
        </div>
    );
};

export default AddAddressModal;
