import { QRCode } from "react-qrcode-logo";
import { useTranslation } from 'react-i18next';
import useModalStore from "../../stores/ModalStore";
import "./EnableTwoFaModal.css";

interface EnableTwoFaModalProps {
    profileData: {
        user: {
            username: string;
        };
    };
    secret: {
        data: string;
    };
    qrImg: string;
    nextTfa: () => void;
    handleClose: () => void;
}
  
const EnableTwoFaModal = ({ profileData, secret, qrImg, nextTfa, handleClose }: EnableTwoFaModalProps) => {

    const { t } = useTranslation('common');

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }));

    const handleSubmitModal = () => {
        nextTfa()
        closeModal()
    }
    
    return (
        <div className="enable-two-fa-modal-cont">
                <div className="enable-two-fa-title">{t("enable_two_fa")}</div>
                <QRCode value={`otpauth://totp/NEMO-2fa-${profileData.user.username}?secret=${secret.data}`} size={200} bgColor="#0F131A" fgColor="#8FFDFC" logoImage={qrImg} logoWidth={70} logoPadding={5} logoPaddingStyle="circle" removeQrCodeBehindLogo={true} ecLevel="Q"/>
                <span className='tfa-code'>{secret.data}</span>
                <span className='tfa-explanation'>{t('tfa_explanation')}</span>
                {/*                     
                <div className='tfa-download-badges-cont'>
                    <img className='tfa-download-badge' src={t('google_download_badge')} />
                    <img className='tfa-download-badge' src={t('apple_download_badge')} />
                </div> 
                */}
                <div className='button-options-cont'>
                    <button className='option-button' style={{backgroundColor: "#ee4747", color: "white"}} onClick={() => {handleClose(); closeModal()}}>{t('cancel')}</button>
                    <button className='option-button' onClick={() => handleSubmitModal()}>{t('ready')}</button>
                </div>
        </div>
    )
}

export default EnableTwoFaModal