import "./ChangePasswordModal.css"
import { useTranslation } from "react-i18next";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

const ChangePasswordModal = () => {

    const { t } = useTranslation('common');

    return (
        <div className="change-password-modal-cont">
            <div className="change-password-title">{t("change_password")}</div>
            <ChangePassword nofloat={false} />
        </div>
    )
}

export default ChangePasswordModal